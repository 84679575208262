<template>
  <div>
    <!-- <b-form-input v-model="test" /> -->
    <!-- <filter-fields :filter-options.sync="filtersField" :column-count="4">
      <template #provinsi>
        <v-select
          v-model="selectedProvinsi"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="provinsiOptions"
          class="w-100"
          label="nama"
          :reduce="val => val.kode"
          placeholder="Pilih Provinsi"
        />
      </template>
      <template #kota>
        <v-select
          v-model="selectedKota"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="kotaOptions"
          class="w-100"
          label="nama"
          :reduce="val => val.kode"
          placeholder="Pilih Kota"
        />
      </template>
    </filter-fields> -->
    <b-card>
      <own-table
        ref="paymentStepTable"
        name-table="payment-step"
        :table-columns="columns"
        :export-excel="false"
        @evt-add="onAdd"
      >
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusVariant(data.data.item.status)">
            <span>{{ data.data.item.status }}</span>
          </b-badge>
        </template>
        <template #cell(is_active)="data">
          <b-badge :variant="statusVariant(data.data.item.is_active)">
            {{ data.data.item.is_active ? 'Aktif' : 'Tidak Aktif' }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <span class="ml-1"
            ><feather-icon icon="EyeIcon" @click="doEdit(data.data.item.id)"
          /></span>
          <span class="ml-1"
            ><feather-icon
              icon="TrashIcon"
              @click="doRemove(data.data.item.id)"
          /></span>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormInput,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardTitle,
  BButton,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  onUnmounted,
  watch,
  watchEffect,
} from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import axios from 'axios'
import vSelect from 'vue-select'
// import FilterFields from '../components/filter/Filter.vue'
import { formatDate } from '@/@core/utils/filter'
import paymentStepStore from './paymentStepStore'

export default {
  components: {
    BCard,
    // FilterFields,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  setup(props, context) {
    const PAYMENT_STEP_STORE = 'payment-step'
    if (!store.hasModule(PAYMENT_STEP_STORE)) {
      store.registerModule(PAYMENT_STEP_STORE, paymentStepStore)
    }
    onUnmounted(() => {
      store.unregisterModule(PAYMENT_STEP_STORE)
    })
    const paymentStepTable = ref(null)
    const regionAPiUrl = ref(
      'https://permohonancustomer.ingco.co.id/public/api'
    )
    const showCreateFrom = ref(false)
    const columns = ref([
      {
        label: 'Kode Bank',
        key: 'bank_code',
      },
      {
        label: 'Nama Bank',
        key: 'bank_name',
        sortable: true,
      },
      {
        label: 'Judul',
        key: 'title',
      },
      {
        label: 'Status',
        key: 'is_active',
        sortable: true,
      },
      {
        label: 'Created At',
        key: 'created_at',
        formatter: value => formatDate(value),
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const queryFilter = ref('')
    const search = ref('')

    const statusVariant = status => {
      if (status) {
        return 'success'
      }
      return 'danger'
    }
    const searchOnChange = val => {
      console.log(val)
      search.value = val
    }
    const setStatus = (data, status) => {
      console.log(data)
      store
        .dispatch(`${PAYMENT_STEP_STORE}/setStatus`, { id: data.id, status })
        .then(response => {
          if (response.status === 200) {
            paymentStepTable.value.refetchData()
          }
        })
    }

    const onAdd = evt => {
      context.root.$router.push({ name: 'payment-step-create' })
    }

    const doEdit = id => {
      context.root.$router.push({ name: 'payment-step-edit', params: { id } })
    }

    const doRemove = id => {
      store.dispatch('payment-step/deletePaymentStep', id).finally(() => {
        paymentStepTable.value.refetchData()
      })
    }

    return {
      columns,
      queryFilter,
      paymentStepTable,
      searchOnChange,
      statusVariant,
      setStatus,
      showCreateFrom,
      onAdd,
      doEdit,
      doRemove,
    }
  },
}
</script>
